import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logged_user: {},
};

const loggedUserSlice = createSlice({
    name:"logged_user",
    initialState,
    reducers:{
        getUserData:(state,action)=>{
            state.logged_user = action.payload
        }
    }
})

export const {getUserData} = loggedUserSlice.actions
export default loggedUserSlice.reducer