import React, { useEffect } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "store/slices/profileSlice";
import { loadProfileDetails } from "services/appServices/editProfileService";
import { getSettings } from "store/slices/settingsSlice";
import { loadSettingDetails } from "services/appServices/siteSettingService";
// import { loadSettingDetails } from "services/appServices/siteSettingService";
// import { getSettings } from "store/slices/settingsSlice";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state?.settings?.data);

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  // useEffect(() => {
  //   loadSettingDetails().then((res) => {
  //     if (res.status === 200) {
  //       dispatch(getSettings(res.data));
  //     }
  //   });
  // }, []);

  const profile = useSelector((state) => state?.profile?.pofileData);

  useEffect(() => {
    loadProfileDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(setProfileDetails(res?.data?.data));
      } else {
        dispatch(setProfileDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    loadSettingDetails().then((res) => {
      if (res.status === 200) {
        dispatch(getSettings(res.data));
      }
    });
  }, []);

  function favIconChange() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings?.favicon?.url;
  }
  favIconChange();

  return <Component {...props} />;
};

export default AppRoute;
